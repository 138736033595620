import React, { useEffect } from "react";
import PageHeader from "../components/pages/about/PageHeader";
import WhoWeAre from "../components/pages/about/WhoWeAre";
import Mission from "../components/pages/about/Mission";
import WhyAwardsMatter from "../components/pages/about/WhyAwardsMatter";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeader />
      <WhoWeAre />
      <Mission />
      <WhyAwardsMatter />
    </>
  );
};

export default About;
