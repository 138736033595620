import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@nextui-org/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

// Define schema with required and email validation
const emailSchema = z.object({
  email: z
    .string()
    .nonempty("Email is required")
    .email("Invalid email address"),
});

const Footer = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(emailSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <footer>
      <div className="bg-[url('/public/images/footer.png')] bg-cover text-textWhite text-center relative">
        <div className="absolute inset-0 bg-footerGradiant"></div>
        <div className="container md:px-[40px] min-w-full relative z-20 flex flex-col items-center py-[60px] sm:py-[120px]">
          <h2 className="sm:text-2xl text-xl leading-[32px] font-bold">
            Get the latest news and updates
          </h2>
          <p className="font-light sm:max-w-[300px] py-2 mb-6">
            Subscribe to our newsletters to follow the news about our fresh
            products.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="sm:w-[380px] w-[280px] h-[48px] shadow-newShadow relative rounded-lg">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      isInvalid={!!errors.email}
                      classNames={{
                        base: "w-full h-full rounded-lg border-1 border-whiteBorder",
                        inputWrapper: "w-full h-full rounded-lg pr-[95px]",
                        input: "subscribe-input",
                      }}
                      placeholder="Enter your email address"
                    />
                    {errors?.email?.message && (
                      <p className="mt-2 text-sm text-left text-danger-100">
                        {errors.email.message}
                      </p>
                    )}
                  </>
                )}
              />
              <Button
                type="submit"
                className="rounded-[4px] bg-textColor w-[80px] h-[32px] flex justify-center items-center text-textWhite shadow-newShadow absolute right-2 top-2"
              >
                Subscribe
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="container md:px-[40px] min-w-full relative z-20 bg-footerBg flex items-center justify-center sm:justify-between gap-10 flex-wrap text-textWhite py-5">
        <p className="">Designed & Developed by M&A</p>
        <p className="text-center flex items-center sm:flex-nowrap flex-wrap gap-y-2.5">
          <div className="sm:w-auto w-full flex items-center sm:justify-start justify-center min-[370px]:flex-nowrap flex-wrap">
            <span className="mr-1.5 min-[370px]:w-auto w-full">
              Copyright © 2025 TBA
            </span>
            <span className="mr-1.5 min-[370px]:block hidden">|</span>
            <Link className="mr-1.5 min-[370px]:w-auto w-full">
              All Rights Reserved
            </Link>
            <span className="mr-1.5 sm:block hidden">|</span>
          </div>
          <div className="flex items-center justify-center w-full sm:w-auto sm:justify-start">
            <Link to="terms-and-conditions" className="underline mr-1.5">
              Terms and Conditions
            </Link>
            <span className="mr-1.5">|</span>
            <Link to="privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
