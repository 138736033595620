import React, { useEffect } from "react";
import AboutTravel from "../components/pages/TravelAwards/AboutTravel";
import PageHeader from "../components/pages/TravelAwards/PageHeader";
import OurPurpose from "../components/pages/TravelAwards/OurPurpose";
import Unique from "../components/pages/TravelAwards/Unique";

const TravelAwards = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageHeader />
      <AboutTravel />
      <OurPurpose />
      <Unique />
    </div>
  );
};

export default TravelAwards;
