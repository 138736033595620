import React from "react";
import Header from "../../general/Header";

const AboutTravel = () => {
  return (
    <div className="container md:px-[40px] min-w-full sm:pt-[60px] sm:pb-[80px] pt-[30px] pb-[40px]">
      <Header title="About The Travel Awards" />
      <h3 className="sm:text-[44px] text-[32px] max-w-[1150px] text-textColor leading-[50.56px] sm:mb-[40px] mb-5">
        <span className="text-secondaryText ">Honoring Excellence in</span>{" "}
        Travel Content Creation
      </h3>
      <p className="text-xl font-light leading-[32px] text-textColor">
        The Travel Award is a prestigious recognition of outstanding travel
        content creators who inspire, educate, and connect audiences through
        their unique perspectives on the world. This award celebrates the
        artistry, dedication, and influence of travel storytelling in all its
        forms.
      </p>
    </div>
  );
};

export default AboutTravel;
