"use client";
import {
  Image,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navLinks } from "../../constants/general/navLinks";

export default function MainNavbar() {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const shouldTextBeBlack =
    pathname === "/privacy-policy" || pathname === "/terms-and-conditions";

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuToggle={setIsMenuOpen}
      shouldHideOnScroll
      style={{
        backdropFilter: "none",
      }}
      classNames={{
        base: "mt-4 bg-transparent filter-none absolute h-[41px z-[99999]",
        wrapper:
          "min-w-full lg:px-[40px] container h-full bg-transparent z-[99999]",
      }}
    >
      <NavbarBrand as={Link} to={"/"} className="max-w-fit">
        <Image
          src={shouldTextBeBlack ? "/images/logoBlack.png" : "/images/logo.png"}
          className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px]"
          classNames={{
            wrapper: "min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px]",
          }}
          alt="travel-bloggers"
        />
      </NavbarBrand>
      <NavbarContent
        className="hidden min-[815px]:flex gap-[23px] max-w-fit"
        justify="center"
      >
        {navLinks.map((link) => (
          <NavbarItem key={link.name}>
            <Link
              className={`${
                shouldTextBeBlack ? "text-black" : "text-white"
              } leading-[27px] text-lg font-normal tracking-[1px]`}
              to={link.href}
            >
              {link.name}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end" className="max-w-fit">
        <NavbarItem>
          <button
            // to="/auth/login"
            className="bg-textColor text-textWhite text-sm lg:w-[134px] lg:h-[41px] min-[425px]:w-[124px] min-[425px]:h-[36px] w-[80px] h-[35px] flex justify-center items-center rounded-[10px] font-medium"
          >
            Log in
          </button>
        </NavbarItem>
        <NavbarItem>
          <button
            // to="/auth/login"
            className={`${
              shouldTextBeBlack ? "border-black" : "border-white"
            } border-2 bg-textWhite text-textColor text-sm lg:w-[134px] lg:h-[41px] min-[425px]:w-[124px] min-[425px]:h-[36px] w-[80px] h-[35px] flex justify-center items-center rounded-[10px] font-medium`}
          >
            Sign up
          </button>
        </NavbarItem>
        <NavbarMenuToggle
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="min-[815px]:hidden visible h-[40px] w-[40px] custom-navbar-icon border-1 border-textColor"
        />
      </NavbarContent>
      <NavbarMenu className="items-center navbar-menu max-h-[60vh] top-[66px] pt-5 rounded-bl-lg rounded-br-lg">
        {navLinks.map((item, index) => (
          <NavbarMenuItem key={`${item.name}-${index}`}>
            <Link
              className="w-full"
              to={item.href}
              size="lg"
              onClick={closeMenu}
            >
              {item.name}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
