import React, { useEffect } from "react";
import PageHeader from "../../components/pages/blogs/PageHeader";
import BlogsHighlights from "../../components/pages/blogs/BlogsHighlights";

const BlogsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeader />
      <BlogsHighlights />
    </>
  );
};

export default BlogsPage;
