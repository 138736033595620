import React from "react";

const Shares = () => {
  return (
    <div className="flex items-center container md:flex-nowrap flex-wrap min-w-full md:px-[40px] md:h-[68px] h-[136px] lg:mb-[100px] md:mb-[60px] mb-[30px]">
      <div className="md:max-w-[25%] md:min-w-[25%] min-w-[50%] max-w-[50%] flex items-center justify-center gap-2 border-b border-lightGray md:h-full h-1/2">
        <span>694</span>
        <p className="text-darkGray">Shares</p>
      </div>
      <div className="md:max-w-[25%] md:min-w-[25%] min-w-[50%] max-w-[50%] flex items-center justify-center gap-2 border-b border-darkBlue md:h-full h-1/2">
        <img
          width={28}
          height={28}
          src="/images/blog/facebook.png"
          alt="facebook"
        />
        <p className="text-darkBlue uppercase font-semibold">Share</p>
        <span className="text-darkGray">694</span>
      </div>
      <div className="md:max-w-[25%] md:min-w-[25%] min-w-[50%] max-w-[50%] flex items-center justify-center gap-2 border-b border-lightBlue md:h-full h-1/2">
        <img
          width={28}
          height={28}
          src="/images/blog/twitter.png"
          alt="twitter"
        />
        <p className="text-lightBlue uppercase font-semibold">Tweet</p>
      </div>
      <div className="md:max-w-[25%] md:min-w-[25%] min-w-[50%] max-w-[50%] flex items-center justify-center gap-2 border-b border-redColor md:h-full h-1/2">
        <img
          width={28}
          height={28}
          src="/images/blog/pinterest.png"
          alt="pinterest"
        />
        <span className="text-darkGray">694</span>
      </div>
    </div>
  );
};

export default Shares;
