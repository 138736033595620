import React from "react";
import Clock from "../../../icons/blog/Clock";
import Statistics from "../../../icons/blog/Statistics";
import SocialMedia from "../../../icons/blog/SocialMedia";

const PageHeader = ({ articleId }) => {
  console.log(articleId);

  return (
    <section className="bg-[url('/public/images/singleBlogHeader.png')] bg-cover md:h-[400px] relative h-auto flex items-center justify-center">
      <div className="container text-textWhite min-w-full md:px-[40px] md:py-0 py-20 relative z-20">
        <h1 className="sm:text-[40px] text-[32px] font-normal xl:w-[90%] leading-[41.36px] uppercase mb-2">
          {articleId === "1" &&
            "7 Truths Learnt from TEDx Speaker Robin Esrock from his 8-Year Travels"}
          {articleId === "2" &&
            "5 Stages of Personal Growth with Adventure Traveler & TEDx Speaker Michelle Spinei"}
          {articleId === "3" &&
            "5 Life Changing Insights about “Traveling with No Money” from 7 Times TEDx Speaker & Musician Edouard Jacqmin"}
        </h1>
        {articleId === "1" || articleId === "2" ? (
          <p className="sm:text-xl text-lg leading-[36px]">
            {articleId === "1" && "TEDxVancouver"}
            {articleId === "2" && "TEDxReykjavik"}
          </p>
        ) : null}
        {articleId === "1" && (
          <div className="flex items-center gap-2.5 md:flex-nowrap flex-wrap mt-5">
            <p className="sm:text-xl text-lg leading-[36px]">
              Written By: Mariam Shouman
            </p>
            <p className="sm:text-xl text-lg leading-[36px]">
              Editor & Publisher: Dr. Mahmoud Mansi
            </p>
          </div>
        )}
        {articleId === "2" && (
          <div>
            <p>Written By: Joudy El Rouby</p>
            <p>Editor & Publisher: Dr. Mahmoud Mansi</p>
          </div>
        )}
        {articleId === "3" && (
          <div>
            <p>Written by: Amira Haytham</p>
            <p>Editor & Publisher: Dr. Mahmoud Mansi</p>
          </div>
        )}
        {/* <div className="min-[810px]:w-auto w-full flex items-center gap-2.5">
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              by Joanna Wellick
            </span>
            <div className="min-[370px]:w-5 w-3 h-px bg-textWhite" />
            <Clock />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              2 minute read
            </span>
            <div className="min-[810px]:w-5 w-0 h-px bg-textWhite" />
          </div>
          <div className="min-[810px]:w-auto w-full flex items-center gap-2.5">
            <Statistics />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              1.6K views
            </span>
            <div className="min-[370px]:w-5 w-3 h-px bg-textWhite" />
            <SocialMedia />
            <span className="sm:text-xl min-[370px]:text-lg text-sm">
              1.2K shares
            </span>
          </div> */}
      </div>
      <div className="absolute left-0 right-0 bottom-0 md:h-[400px] h-auto bg-heroGradiant"></div>
    </section>
  );
};

export default PageHeader;
