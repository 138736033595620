import React from "react";
import Header from "../../general/Header";

const WhoWeAre = () => {
  return (
    <div className="container sm:pt-[40px] sm:pb-[60px] pt-[20px] pb-[30px] min-w-full md:px-[60px] mx-0">
      <Header title="Who we are" />
      <h3 className="sm:text-[44px] text-[32px] max-w-[1150px] text-textColor leading-[50.56px]">
        <span className="text-secondaryText ">Celebrating the Best in</span>{" "}
        Travel Storytelling!
      </h3>
      <p className="sm:text-xl text-lg text-textColor leading-[32px] font-light my-5">
        he Travel Bloggers Awards is a premier platform recognizing and
        celebrating the creativity, passion, and impact of travel bloggers
        worldwide. Our mission is to honor those who inspire global exploration
        through compelling storytelling.
      </p>
    </div>
  );
};

export default WhoWeAre;
