import React from "react";

const Header = ({ title }) => {
  return (
    <div className="flex items-center gap-2 mb-4">
      <div className="w-[72px] h-0.5 bg-grayColor" />
      <h2 className="text-grayText text-base font-normal">{title}</h2>
    </div>
  );
};

export default Header;
