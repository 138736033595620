import { Image } from "@nextui-org/react";
import React from "react";

const Mission = () => {
  return (
    <section className="container sm:pt-[45px] sm:pb-[45px] pt-[20px] pb-[20px] min-w-full sm:px-[60px] mx-0">
      <div className="flex items-center justify-between md:flex-nowrap flex-wrap gap-10 sm:pb-[90px] pb-[45px]">
        <Image
          src="/images/about/mission.png"
          alt="Mission"
          classNames={{
            wrapper:
              "lg:min-w-[380px] lg:min-h-[310px] lg:max-w-[380px] lg:max-h-[310px] md:min-w-[280px] lg:w-[380px] lg:h-[310px] md:min-h-[210px] md:max-w-[280px] md:max-h-[210px] md:w-[280px] md:h-[210px] w-full rounded-none md:order-1 order-2",
          }}
          className="rounded-none max-w-full max-h-full w-full h-full object-cover"
        />
        <div className="md:order-2 order-1">
          <h3 className="font-medium sm:text-[40px] text-[32px] text-textColor">
            <span className="text-secondaryText">Our</span> Mission
          </h3>
          <p className="mt-4 sm:text-xl text-lg font-light leading-8">
            A concise paragraph explaining why the awards were created.Travel
            blogging connects people to the wonders of the world, breaking
            cultural barriers and inspiring journeys. Our mission is to
            spotlight the talent and hard work of bloggers who bring these
            stories to life.
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between md:flex-nowrap flex-wrap gap-10">
        <div>
          <h3 className="font-medium sm:text-[40px] text-[32px] text-textColor">
            <span className="text-secondaryText">Our</span> Vision
          </h3>
          <p className="mt-4 sm:text-xl text-lg font-light leading-8">
            We dream of a world where travel stories inspire connection,
            understanding, and curiosity about the beauty and diversity of our
            planet. Our vision is to create a global platform where exceptional
            travel content not only entertains but fosters a deeper appreciation
            for cultures, people, and the shared human experience.
          </p>
        </div>
        <Image
          src="/images/about/vision.png"
          alt="vision"
          classNames={{
            wrapper:
              "lg:min-w-[380px] lg:min-h-[310px] lg:max-w-[380px] lg:max-h-[310px] md:min-w-[280px] lg:w-[380px] lg:h-[310px] md:min-h-[210px] md:max-w-[280px] md:max-h-[210px] md:w-[280px] md:h-[210px] w-full rounded-none",
          }}
          className="rounded-none max-w-full max-h-full w-full h-full object-cover"
        />
      </div>
    </section>
  );
};

export default Mission;
