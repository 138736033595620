import React, { useEffect } from "react";
import PageHeader from "../components/pages/contact/PageHeader";
import BecomeAContestant from "../components/pages/contact/BecomeAContestant";
import ContactForm from "../components/pages/contact/ContactForm";

const ContactPage = () => {
  const onSubmit = (data) => {
    console.log(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PageHeader />
      <BecomeAContestant />
      <ContactForm onSubmit={onSubmit} />
    </div>
  );
};

export default ContactPage;
