import React from "react";

const PageHeader = () => {
  return (
    <section className="bg-[url('/public/images/travelAwards/header.png')] bg-cover relative h-[384px] flex items-center justify-center">
      <div className="container relative z-20">
        <h1 className="uppercase font-bold sm:text-[48px] text-[36px] text-center text-textWhite">
          Travel Awards
        </h1>
      </div>
      <div className="absolute left-0 right-0 bottom-0 h-[384px] bg-heroGradiant"></div>
    </section>
  );
};

export default PageHeader;
