import React, { useState } from "react";
import Header from "../../general/Header";
import { blogs } from "../../../constants/general/blogs";
import ArticleCard from "../../general/ArticleCard";
import Pagination from "./Pagination";

const BlogsHighlights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <section className="container sm:pt-[60px] sm:pb-[80px] pt-[30px] pb-[40px] min-w-full sm:px-[40px] mx-0">
      <div className="lg:mb-[160px] md:mb-[80px] mb-[40px]">
        <Header title="Blogs Highlights" />
        <h3 className="sm:text-[44px] text-[32px] mb-5 sm:mb-10 text-textColor leading-[50.56px]">
          <span className="text-secondaryText">The</span> Travel Blogger's{" "}
          <span className="text-secondaryText">Hub</span>
        </h3>
        <p className="sm:text-xl text-lg leading-[32px] text-textColor font-light">
          Our blog is dedicated to helping travel bloggers make the most of
          their adventures. From monetization strategies and SEO tips to content
          creation and community highlights, we provide the tools you need to
          grow your blog, reach your audience, and maximize your income.
          Discover the latest insights and get inspired!
        </p>
      </div>
      <div className="grid xl:grid-cols-3 md:grid-cols-2 lg:gap-10 md:gap-5 gap-10 xl:w-full lg:w-[90%] md:w-full min-[460px]:w-[80%] xl:mx-0 mx-auto">
        {blogs.map((blog, idx) => (
          <ArticleCard page={"blogs"} key={idx} article={blog} />
        ))}
      </div>
      <Pagination
        lastPage={10}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </section>
  );
};

export default BlogsHighlights;
