import React from "react";
import ArrowDown from "../../icons/ArrowDown";
import { Image } from "@nextui-org/react";

const CoreFeatures = () => {
  const featuresData = [
    {
      title: "Engage and create",
      desc: "Meet travel bloggers from every corner of the world, discover new perspectives, and make lifelong connections.",
      featureImg: "/images/home/learnToMonetize.png",
    },
    {
      title: "Collaborate on Projects",
      desc: "Find partners for co-writing, photo projects, or joint brand campaigns, expanding your reach and creativity.",
      featureImg: "/images/home/engageAndCreate.png",
    },
    {
      title: "Learn to Monetize",
      desc: "Access our monetization toolkit, webinars, and workshops",
      subDesc:
        "led by experienced bloggers who’ve made their passion profitable.",
      featureImg: "/images/home/collaborate.png",
    },
  ];
  return (
    <section className="container sm:pt-[40px] sm:pb-[80px] pt-[20px] pb-[40px] min-w-full md:px-[40px] mx-0">
      <h3 className="sm:text-[44px] text-[32px] text-textColor mb-5 sm:mb-10 leading-[50.56px] ">
        <span className="text-secondaryText">Our core</span> Features
      </h3>
      <p className="lg:mb-[50px] mb-[25px] sm:text-2xl text-lg leading-[32px] text-textColor">
        Our community is built for travel bloggers who want to turn their
        passion for travel into a successful venture. Whether you’re new to
        travel blogging or a seasoned pro, you’ll find opportunities to connect,
        learn, and grow with like-minded creators worldwide. From brand
        collaborations to exclusive monetization tips, we provide the tools and
        network you need to succeed
      </p>
      {featuresData.map((item, idx) => (
        <div
          key={idx}
          className={`grid lg:grid-cols-2 justify-between items-center lg:gap-20 gap-[25px] ${
            idx !== 2 ? "lg:mb-0 mb-10" : ""
          } ${idx !== 0 ? "lg:mt-[-40px]" : ""}`}
        >
          <div className={`${idx === 1 ? "lg:order-2" : ""}`}>
            <div className="flex items-center gap-2.5 mb-4 lg:justify-start justify-center">
              <ArrowDown />
              <p className="text-textColor font-medium text-2xl">
                {item.title}
              </p>
            </div>
            <p className="text-xl leading-[32px] font-light text-textColor lg:w-auto sm:w-3/4 lg:mx-0 mx-auto lg:text-left text-center">
              {item.desc}
            </p>
            {item.subDesc && (
              <p className="text-xl leading-[32px] font-light text-textColor lg:w-auto sm:w-3/4 lg:mx-0 mx-auto lg:text-left text-center">
                {item.subDesc}
              </p>
            )}
          </div>
          <Image
            classNames={{
              wrapper: `rounded-none lg:w-auto sm:w-3/4 lg:mx-0 mx-auto ${
                idx === 1 ? "lg:order-1" : ""
              }`,
            }}
            src={item.featureImg}
            className="rounded-none"
          />
        </div>
      ))}
    </section>
  );
};

export default CoreFeatures;
