import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Pagination = ({ lastPage, currentPage, setCurrentPage }) => {
  const increasePage = (page) => {
    if (currentPage < lastPage) {
      setCurrentPage(page || currentPage + 1);
    } else {
      setCurrentPage(lastPage);
    }
  };
  const decreasePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  return (
    <div className="flex items-center justify-center gap-2 text-sm my-16">
      <button
        className="rounded-[4px] w-8 h-8 flex items-center justify-center text-textWhite bg-textColor"
        onClick={decreasePage}
      >
        <ChevronLeft size={18} />
      </button>
      {currentPage < lastPage - 2 && (
        <>
          <button className="rounded-[4px] font-bold border-1 border-textColor text-textColor w-8 h-8 flex items-center justify-center">
            {currentPage}
          </button>
          <button
            className="rounded-[4px] border-paginationBorder border-1 w-8 h-8 flex items-center justify-center"
            onClick={increasePage}
          >
            {currentPage + 1}
          </button>
        </>
      )}
      <p className=" rounded-[4px] border-paginationBorder border-1 w-8 h-8 flex items-center justify-center">
        ...
      </p>
      <button
        className=" rounded-[4px] border-paginationBorder border-1 w-8 h-8 flex items-center justify-center"
        onClick={() => increasePage(lastPage - 1)}
      >
        {lastPage - 1}
      </button>
      <button
        className=" rounded-[4px] border-paginationBorder border-1 w-8 h-8 flex items-center justify-center"
        onClick={() => increasePage(lastPage)}
      >
        {lastPage}
      </button>
      <button
        className=" rounded-[4px] w-8 h-8 flex items-center justify-center text-textWhite bg-textColor"
        onClick={increasePage}
      >
        <ChevronRight size={18} />
      </button>
    </div>
  );
};

export default Pagination;
