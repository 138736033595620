import React from "react";
import Header from "../../general/Header";
import { members } from "../../../constants/home/members";
import MemberCard from "./MemberCard";

const SuccessfulMembers = () => {
  return (
    <section className="container sm:pt-[40px] sm:pb-[80px] pt-[20px] pb-[40px] min-w-full md:px-[40px] mx-0">
      <Header title="Succesful Members" />
      <h3 className="sm:text-[44px] text-[32px] leading-[50.56px] mb-5 sm:mb-10 text-textColor">
        Spotlight <span className="text-secondaryText">on Our Community</span>
      </h3>
      <p className="font-light sm:text-xl text-lg leading-[32px]">
        Our community members are at the heart of everything we do. Here, travel
        bloggers with unique stories, perspectives, and skills come together to
        share and inspire. Whether it’s advice on capturing the perfect photo or
        tips on negotiating brand deals, our members continuously elevate each
        other’s work.
      </p>
      <div className="flex gap-10 justify-center lg:flex-nowrap flex-wrap mt-10">
        {members.map((member, idx) => (
          <MemberCard index={idx} key={member.id} member={member} />
        ))}
      </div>
    </section>
  );
};

export default SuccessfulMembers;
