import React, { useEffect } from "react";

const TermsAndConditionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="mt-[120px] container md:px-[80px] min-w-full mb-10">
      <h3 className="sm:text-[32px] text-[24px] font-bold text-textColor leading-[50.56px] mb-2">
        Terms of Service
      </h3>
      <div className="text-xl leading-9">
        <p>Effective Date: July 1, 2024</p>
        <p>
          Welcome to TRAVEL BLOGGERS AWARDS! These Terms of Service ("Terms")
          govern your use of our services. By accessing or using the services of
          M&A, you agree to comply with and be bound by these Terms. Please read
          them carefully.
        </p>
        <ol className="list-decimal">
          <li className="ml-6">Acceptance of Terms</li>
          <p>
            By accessing and using our services, you agree to be bound by these
            Terms, as well as our Privacy Policy. If you do not agree to these
            Terms, you may not use our services.
          </p>
          <li className="ml-6"> Services Provided</li>
          <p>
            M&A offers [describe the services provided, e.g., marketing,
            consulting, etc.]. We strive to deliver high-quality services and
            work collaboratively with our clients to meet their needs.
          </p>
          <li className="ml-6">User Responsibilities</li>
          <p>As a user of our services, you agree to:</p>
          <ul className="list-disc">
            <li className="ml-5">
              Provide accurate and complete information when requested.
            </li>
            <li className="ml-5">
              Comply with all applicable laws and regulations.
            </li>
            <li className="ml-5">
              Not use our services for any unlawful or prohibited activities.
            </li>
          </ul>
          <li className="ml-6"> Payment Terms</li>
          <p>
            You agree to pay for the services provided by M&A as outlined in the
            service agreement. Payments are due [specify payment terms, e.g.,
            upon receipt, within 30 days, etc.]. Late payments may be subject to
            additional fees.
          </p>
          <li className="ml-6">Confidentiality</li>
          <p>
            We respect the confidentiality of our clients. Any information
            shared with us will be kept confidential and used only for the
            purpose of providing our services.
          </p>
          <li className="ml-6"> Limitation of Liability</li>
          <p>
            M&A shall not be liable for any indirect, incidental, special,
            consequential, or punitive damages arising out of or relating to the
            use of our services.
          </p>
          <li className="ml-6">Termination</li>
          <p>
            We reserve the right to terminate or suspend your access to our
            services at any time, without prior notice, for conduct that we
            believe violates these Terms or is harmful to other users of our
            services or us.
          </p>
          <li className="ml-6">Changes to Terms</li>
          <p>
            We may update these Terms from time to time. Any changes will be
            posted on our website and will become effective immediately upon
            posting.
          </p>
          <li className="ml-6">Governing Law</li>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of [your jurisdiction].
          </p>
          <li className="ml-6"> Contact Us</li>
          <p>
            If you have any questions about these Terms, please contact us at
            [your contact information].
          </p>
        </ol>
      </div>
    </section>
  );
};

export default TermsAndConditionsPage;
