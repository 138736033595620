import React, { useEffect } from "react";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="mt-[120px] container md:px-[80px] min-w-full mb-10">
      <h3 className="sm:text-[32px] text-[24px] font-bold text-textColor mb-2">
        <span className="text-secondaryText ">Privacy Policy for</span> Travel
        Bloggers Awards
      </h3>
      <div className="text-xl leading-9">
        <p>Effective Date: July 1, 2024</p>
        <p>
          Welcome to TRAVEL BLOGGERS AWARDS. We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about our policy, or our practices with regards
          to your personal information, please contact us at support@M&A.com
        </p>
        <ol className="list-decimal">
          <li className="ml-6">What Information Do We Collect?</li>
          <p>
            Personal Information You Disclose to Us: We collect personal
            information that you voluntarily provide to us when registering at
            the Applications, expressing an interest in obtaining information
            about us or our products and services, when participating in
            activities on the Application or otherwise contacting us. The
            personal information that we collect depends on the context of your
            interactions with us and the Application, the choices you make, and
            the products and features you use. The personal information we
            collect can include the following: Names, Email addresses, and
            Contact details.
          </p>
          <li className="ml-6">How Do We Use Your Information?</li>
          <p>
            We use personal information collected via our Application for a
            variety of business purposes described below. We process your
            personal information for these purposes in reliance on our
            legitimate business interests, in order to enter into or perform a
            contract with you, with your consent, and/or for compliance with our
            legal obligations
          </p>
          <li className="ml-6">Will Your Information Be Shared With Anyone?</li>
          <p>
            We may process or share your data that we hold based on the
            following legal basis: Consent: We may process your data if you have
            given us specific consent to use your personal information for a
            specific purpose. Legitimate Interests: We may process your data
            when it is reasonably necessary to achieve our legitimate business
            interests.
          </p>
          <p>
            Performance of a Contract: Where we have entered into a contract
            with you, we may process your personal information to fulfill the
            terms of our contract.
          </p>
          <li className="ml-6"> What Are Your Privacy Rights?</li>
          <p>
            In some regions (like the European Economic Area), you have rights
            that allow you greater access to and control over your personal
            information. You may review, change, or terminate your account at
            any time
          </p>
          <li className="ml-6">Contact Us</li>
          <p>
            If you have questions or comments about this policy, you may contact
            us through email at support@aimedrev.com
          </p>
          <li className="ml-6">How to Delete Your Account?</li>
          <p>If you wish to delete your account, follow these simple steps:</p>
          <ol className="list-decimal">
            <li className="ml-6">
              Open the app and access the side menu by tapping on the menu icon.
            </li>
            <li className="ml-6">
              {" "}
              In the side menu, scroll down to find the option labeled 'Delete
              My Account.
            </li>
            <li className="ml-6">
              Select 'Delete My Account' and follow the on-screen instructions
              to confirm your account deletion.
            </li>
            <p>
              Please note that deleting your account will permanently remove
              your data from our system. If you have any issues or require
              further assistance, feel free to contact our support team.
            </p>
          </ol>
        </ol>
      </div>
    </section>
  );
};

export default PrivacyPolicyPage;
