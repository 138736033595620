import { Image } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

const ArticleBody = ({ articleId }) => {
  const blog1Items = [
    {
      title:
        "1-Anyone who has traveled for a long time, they are either running away from something, or looking for something!",
      desc: "When you start traveling you should know your purpose of the journey. Maybe you are running away from your own life, memories and mistakes. Or looking for something, thinking about the life you weren’t living, the places you weren’t seeing and the people you weren’t meeting. When you figure out why do you want to travel, take the risk and start your journey.",
    },
    {
      title:
        "2-Whatever decision you make is the best decision you could possibly make!",
      desc: "Our lives are mitigated by routine. When you start your traveling journey decision-making begins to take on a new significance. Every morning you think where are you going to eat, sleep or find a clean toilet? Once these basic life needs were met you start facing a deeper, familiar crises. You begin questioning yourself and your decisions smelling that greener grass. When there are so many decisions to make sometimes it’s just easier not to make any. Esrock stated that “you feel that acuity when you travel independently and you feel that acuity when you don’t, this is called a decision paralysis”. To overcome this crisis, you should definitely know that wherever you are is where you are supposed to be. Once you truly understand that decision making will be a lot easier.",
    },
    {
      title: "3-People would rather help you than hurt you!",
      desc: "Esrock believes that travelers become victims when they look like victims. Some people think that the moment they get off the plane they will be kidnapped, threatened or even killed especially while traveling to developing countries where all you hear is bad news. As Esrock stated “If it bleeds it leads”. From Esrock’s personal experience after traveling to a 107-countries on 6 continents without being robbed or attacked he concluded that either he is extremely lucky or as he likes to believe as one of the vast majority of travelers. From his personal experience, Albania is the friendliest country in the world. Finally, he understood that locals take pride in showing the traveler their world, their untold stories and their culture. Of course there are some places that we shouldn’t go and explore although he believes that travelers are seldom targeted, locals might want to kill each other but they don’t go after tourists for anything other than petty crime which can be dismissed with the basic amount of common sense.",
    },
    {
      title: "4- Smile when you’re nervous and laugh when you are scared!",
      desc: "Definitely people will attack if you provoke them, and everywhere you will find good people and bad ones but the weapon you should always have with you is your smile. There’s something about smiling that projects warmth and honesty. As Esrock believes “ It’s like a softening of the soul” that’s why people love travelling in places like Africa and India even though it can be very challenging. The locals and the people you meet there disarm you with their warmth and friendliness. Finally, Esrock advises us to smile when we’re nervous and laugh when we are scared because it sure beats screaming and crying.",
    },
    {
      title: "5-Trust your guts!",
      desc: "Esrok believes that the more we use our instinct, the more we hear it, the more we use it and the more chance we have to avoid whatever it is that’s trying to save us from. Train yourself to listen to your instinct and follow your heart it always knows the way.",
    },
    {
      title: "6-People you meet create the paradise you find!",
      desc: "It’s all about the people and the paradise, literally. Paradise islands, epic festivals and wild parties, it doesn’t mean much if you aren’t connecting and not sharing with the right people. Simply because, people we meet on a journey out there shape the world. They shape the way we look at everything. They make you want to get off the couch, switch off reality TV, and start participating in reality itself.",
      secondaryDesc:
        "As much as Esrock traveled around the world, it’s his friends mean the world to him. They take him in places he’d never thought he’d get to. The more you travel, the more people you will meet and the more friends you will have.",
    },
    {
      title: "7-Don’t panic!",
      desc: "These two words have helped him when he really needed them like when he jumped off the Sky Tower in Monaco. They were there for him when he came to face with death deep in an ancient burial cave in the South Pacific, and when he came out there in front of 2000 people at the largest TEDx ever held anywhere. Don’t panic, surround yourself with good people, take risks, enjoy the moment and live.",
      secondaryDesc:
        "Esrock states that he has been fortunate to chase his passion to a degree of absurdity. He has run around the world ticking of one bucket list after another and he believes that his success in a very competitive industry like travel media is due to applying these truths that he has learned out there into his daily bizarre world. The lessons that he has learned from his travels keep applying to the world he lives in his daily life because he doesn’t believe that we ever stop travelling and we never stop growing So when you decide to start your own journey make sure you pack the right state of mind, smile, listen to your guts, experience new things, dive into new cultures, listen to the unspoken stories, get to know new people and most importantly live each moment of your journey as if it’s the last one.",
      heading: "More About the Speaker:",
      ternaryDesc:
        "Robin Esrock (Instagram) created and hosted a travel show called World Travels filmed in 36 countries and in 21 languages. His journey Modern Gonzo started when he had a bike accident in 2004. Struck down on his bike at a Vancouver intersection and broke his kneecap. This accident was a turning point in his life. A year later, he received a 20,000 dollars insurance settlement and decided to quit his job, pack up his things and set off on a one-year solo round-the-world backpacking adventure to 24 countries before giving his TEDx speech. Now he has travelled more than 110 countries with recorded experiences.",
    },
  ];

  const blog2Items = [
    {
      title: "1. Feeling Discomfort",
      desc: " Discomfort is a common initial response to a new place or unfamiliar person, much like in daily life. It’s natural to feel unsettled during travel, but these feelings often mirror those we encounter back home. Michelle describes the situation like “hiking on the moon” which could give many different meanings and mixed emotions. Waking there would feel like the world is flying alongside you, however the cold idea that you are out of your planet and beyond your comfort zone causes this positive discomfort. Although “walking on the moon” and out of your plant may make you feel alienated, yet it shall make you more human as “it tests your strength and endurance”.",
    },
    {
      title: "2. Facing Challenges",
      desc: "No matter how well you plan, challenges are inevitable—whether it’s a blocked trail, unexpected weather, or physical discomfort like sore feet. These obstacles can trigger anxiety and fear of the unknown, but they’re part of the adventure. Michelle stated that “the landscape looks better after going through some conflict” or perhaps “the landscape allows us to face our internal conflicts.” Because of a conversation she had with a group of fellow hikers that sparked from complaining about the trail and physical feelings then it turned into deep developed conversations about life and how people are struggling and using the adventure to get out of stressful situations by triggering new situations that clear the mind, leaving you on the surface of our “moon” witnessing one of the best human feelings ever, which is accomplishment.",
    },
    {
      title: "3. Taking Risks",
      desc: "Going on an adventure trip is the last form of socially acceptable risk taking. However, numerous people don’t realize how crucial these quick reflexes are. For instance, changing the route when the trail is blocked or pause for rest if they have sore feet. In Michelle’s TEDx Talk she stated that, “we live in a risk-averse society” which means that from a very young age children are told to stay safe and never come close to danger, but as soon as they become adults, simple decisions could be haunting. As a result a weak indecisive person will grow, a simple way to solve this is by going on an adventure travel, and learning how to wisely and safely deal with any dangers or hazards, and make split second decisions that will blossom your mind into a complex of higher intellectual and emotional intelligence.",
    },
    {
      title: "4. Connecting with Others",
      desc: "On the trail to your destination, you could possibly meet with other fellow travelers, different hikers, each hiking for a different quest, yet surprisingly similar. Firstly, the conversations could start with casual discussions then it evolves to deeper conversations unearthing confessions and opinions about life, work, relationships or personal worries, where the journey itself allows you to free your own personal conflicts to a fellow hiker that might be an absolute stranger and to the wilderness. These types of conversations along the adventure will cleanse your past and shape your future. And, these types of relationships will have a spiritual bond that might last for a lifetime.",
    },
    {
      title: "5. Reaching the Destination",
      desc: "After an exhausting journey to your destination, you will finally realize that everything is exceedingly different as a new and unique perspective arises in your mind. Instead of feeling a huge balloon of relief filling your chest after having ginormous conflicts to reach your desired place, you’ll realize that the famous quote of “success is a journey not a destination” is so true, as it sinks deeper into your mind. As this will prove and emphasize how simple life truly is.",
      secondaryDesc:
        "Soon enough you will land on “Earth”, return back home, with a sharper perspective, a brighter mind and heart, while being less anxious and quicker thinking with better problem-solving skills. As small as these new skills and improvements are, as mighty as they could save an entire society, whether through a TEDx Talk, a book, a movie, or simply through touching the hearts and minds of others with your unique perspectives and adventurous stories.",
      heading: "More About our Traveler and TEDx Speaker:",
      ternaryDesc:
        "Michelle Spinei is a travel influencer in so many distinctive ways. She is a travel entrepreneur where she co-founded an online platform named Koride for connecting travelers. Michelle is also a travel writer with various publications in several travel magazines with an interesting writing piece about travel influencers and other topics, in addition to being an academic researcher specialized in tourism with a Master of Science degree in Natural Resource Recreation and Tourism from the University of Iceland, and has been working in the travel industry across several organizations including the TripAdvisor. Currently she is the International Relations Manager of Iceland Innovation Week.",
    },
  ];

  const blog3Items = [
    {
      title: "Hitchhiking Where You Only Have One Option",
      desc: "For 21 days, Eduoard had no money for transportation, so he hitchhiked his way through europe. To no one's surprise, he discovered that not a lot of people like hitchhikers, and many people refused to give him a ride just because they were mad at the concept itself. But he also met a lot of kind drivers who did offer him help, and one of the most remarkable experiences was when a Ukrainian man offered him a ride in a very dirty truck. Eduoard sat with the ukrainian man for 2 hours laughing and nodding while the man talked gibberish. After that day, Eduoard reflected on how he had no choice but to ride with the Ukrainian in the dirty truck, how he didn't have the luxury of choosing a better looking ride, and how despite his initial disgust, it was one of the funniest rides of his life.",
    },
    {
      title: "Begging for Accomodation",
      desc: "What would you say if a stranger came up to you in the street and asked, “can I spend the night at your house?” Personally, I’d admire the courage it took to ask such a question. Eduoard went up to many strangers with that odd request, many replied with a furrowed face and a confused ‘no’. However, in the 21 days Eduoard always had a roof above his head. Facing the fear of embarrassment and rejection is something most of us cannot handle, and at the risk of sleeping on the street, Eduoard faced the fear with no shame. And surprisingly, people offering help aren’t murderers or thieves, they’re kind and full of hospitality.",
    },
    {
      title: "Rejection is Essential",
      desc: "From drivers on the road and strangers on the street, Edouard also approached workers at restaurants, asking for leftovers or perhaps free food. Some agreed, some yelled at him and some simply ignored him, but the rejection shaped Eduoard’s mind in a way he appreciated. Whenever he received a ‘no’, he didn’t find himself thinking of the worst-case scenarios, but he thought, “I’ll find something else.” He highlights how important it is for us to handle rejection, and see it as a blessing in disguise instead of a crisis waiting to happen.",
    },
    {
      title: "Is Codependency Really A Bad Thing?",
      desc: "Money is freedom and independence, this is what we’re taught our whole lives, and while it is a fact no one denies, is it always a good thing? Edouard depended on strangers for 21 days to feed him, drive him around and put a roof over his head. At the beginning of his journey, when he emptied his wallet and took off, he had one question on his mind: What can I get from this person? Food? A house? Perhaps he’ll be kind enough to provide cash. Edouard’s question changed to “What can I give this person? A smile? A talk? A memorable moment?” He learnt that codependency showed him that connections and experiences with people are priceless.",
    },
    {
      title: "Should you try it out?",
      desc: "The short answer is no, but sometimes answers aren’t as simple as the questions we ask. On his trip, the lesson wasn’t to learn how important money is, but how it blinds us from facing problems and experiences needed for our growth as human beings. Eduoard jokes about how he wouldn’t tell anyone to leave their wallet at home and go on a venture across Europe, but he leaves his listening crowd touched by his story, and questioning how money shaped their own lives.",
    },
    {
      title: "More About our Traveler and TEDx Speaker:",
      desc: "Edouard Jacqmin, a 29-year-old composer, songwriter, and producer from Belgium, has been making waves in the music scene since graduating from Middlesex University of London with a degree in music composition in 2015. Over the past year and a half, he’s been actively releasing music and performing. In June 2016, he embarked on a daring 21-day adventure across Eastern Europe, traveling without a cent to his name. Armed with only his guitar and a bold spirit, he hitchhiked and asked strangers, “Can I stay at your place tonight?”. This unique experience led to the creation of his debut EP, Happily, which was released in November 2016 and features songs inspired by Slovakia, the Czech Republic, and Hungary. Now based in Brussels, Edouard is focused on crafting his first full-length album.",
    },
  ];
  return (
    <section className="bg-[url('/public/images/blog/single-blog-bg.png')] bg-cover bg-center">
      <div className="container min-w-full lg:px-[100px] md:px-[40px] md:pt-[40px] pt-[20px] md:pb-[70px] pb-[30px]">
        {articleId === "1" && (
          <p className="sm:text-lg text-secondaryBlack leading-[33px] sm:mb-20 mb-10">
            Imagine stepping into a vibrant tapestry of cultures, where every
            corner tells a new story and every experience sparks curiosity. When
            you travel you experience a whole new world extremely different from
            yours. Travelling abroad is like diving into the unknown, diving
            into a world full of mysteries and adventures. Travelling abroad
            reshapes your personality where you start to see the world from a
            new perspective. In this article, we will delve into the
            transformative power of educational travel, highlighting its
            benefits and sharing some lessons to learn before embarking your
            travel journey. On the 21st of October 2012 on the stage of
            TEDxVancouver in Canada, Robin Esrock – global adventurer, travel
            writer and T.V. producer – presented a marvelous TEDx Talk entitled
            “Learn to Travel - Travel to Learn” reaching 1.1 Million views today
            and still receiving comments after 11 years of publication, one of
            them was from YouTube user @a_p3242 in 2024: “Over a decade old and
            still relevant today”. So what makes this trendy talk so special and
            on demand for more than 11 years? Link to TEDx Talk (to be embedded
            as a video in the article when publishing it:
            <Link
              to={"https://www.youtube.com/watch?v=S0TemlxiMdw"}
              target="_blank"
              className="text-linkColor ms-1"
            >
              https://www.youtube.com/watch?v=S0TemlxiMdw
            </Link>
          </p>
        )}

        {articleId === "2" && (
          <p className="sm:text-lg text-secondaryBlack leading-[33px] sm:mb-[30px] mb-5">
            Have you ever wondered why people return from travel with new
            perspectives? Their journeys vary greatly. A relaxing beach vacation
            offers safe transportation and the joy of unwinding. Others travel
            for work, to visit family, for research, cultural experiences,
            education, shopping, or adventure. Adventure travel, in particular,
            can be exhilarating and exhausting—but with this comes all the gems!
            It’s one of the few types of travel that can change you 180 degrees.
            On the 13th of October 2019 on the stage of TEDxReykjavik in
            Reykjavik the beautiful capital of Iceland, Michelle Spinei – a
            travel expert in the Arctic region and Nordic culture – explores how
            adventure travel brings about discomfort and risk. In her TEDx Talk
            “Can Adventure Travel Change You?” Spinei explains that through
            these rough experiences, travelers can gain new perspectives in
            their everyday lives. Her insights stem from her 2017 research in
            Iceland during a surge in adventure tourism with an interesting
            group of hikers. Here are Spinei’s 5 stages of change and personal
            growth through adventure traveling:- Link to TEDx Talk (to be
            embedded as a video in the article when publishing it):
            <Link
              to={"https://www.youtube.com/watch?v=Sv1B0IonzqM"}
              target="_blank"
              className="text-linkColor ms-1"
            >
              https://www.youtube.com/watch?v=Sv1B0IonzqM
            </Link>
          </p>
        )}

        {articleId === "3" && (
          <div className="sm:mb-10 mb-5">
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              Seven years ago Edouard Jacqmin - a Belgian composer, songwriter
              and passionate traveler - decided to go on a lifetime challenge of
              having a 21-day Euro trip with no money or internet, perhaps for
              the sake of adventure, discovery, or seeking inspiration to launch
              a new album. After this life changing challenge, Edouard achieved
              another milestone by giving 7 TEDx Talks in a 4 month duration in
              6 different countries about “Traveling with No Money” narrating
              his travel adventures that are full of travel tips and profound
              life lessons.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              Jacqmin’s public speaking quest started in early 2017, when he
              embarked on an inspiring cultural tour across the stages of
              Europe. His story-telling journey then began on the 4th of
              February in France at TEDxSciencesPoCampusMenton, where he
              captivated the audience under the Mediterranean sun. On the 11th
              of March, he continued to the Netherlands, where the snow began to
              melt, delivering his second talk at TEDxYouth@Maastricht amidst
              the city’s charming canals. By the 19th of March, he found himself
              in the historic city of Zaragoza, Spain, sharing his ideas at
              TEDxZaragoza against a backdrop of Moorish and Gothic influences.
              Returning to France on 2nd of May, he spoke at
              TEDxArtsEtMétiersAngers, blending his message with the region’s
              rich history. A few days later, on 6th of May, he engaged the
              audience at TEDxTechnicalUniversityofCrete in Greece, surrounded
              by the island’s ancient beauty. On 9th of May, he addressed his
              home country of Belgium at TEDxUCLouvain where academic spirit met
              his vision. His European adventure concluded on 13th of May in the
              serene English countryside at TEDxUniversityofKent. Across
              continents and cultures, his talks left a trail of inspiration and
              connection.
            </p>
            <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-2.5">
              In his TEDx talks he announced that the first thing he chose to do
              before going on his journey from Germany to Hungary is “empty his
              wallet”. To answer the questions roaming in your mind; is it
              realistic, is it an experience you should try, and what was the
              point?
            </p>
            <h4 className="sm:text-2xl text-lg text-secondaryBlack leading-[33px] font-medium">
              Here’s how he made it happen:
            </h4>
          </div>
        )}

        {articleId === "1" && (
          <h2 className="uppercase font-medium sm:text-[48px] md:text-[36px] text-xl leading-[55.15px] sm:mb-10 mb-5">
            These are some truths he shared with us from his 8-year traveling
            experience:-
          </h2>
        )}
        {articleId === "2"
          ? blog2Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-secondaryBlack mb-2 font-medium">
                    {item.title}
                  </h3>
                )}
                <p
                  className={`sm:text-lg text-secondaryBlack leading-[33px] ${
                    item.secondaryDesc ? "mb-0" : "mb-5"
                  }`}
                >
                  {item.desc}
                </p>
                {item.secondaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.secondaryDesc}
                  </p>
                )}
                {item.heading && (
                  <h3 className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.heading}
                  </h3>
                )}
                {item.ternaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.ternaryDesc}
                  </p>
                )}
              </div>
            ))
          : null}
        {articleId === "1"
          ? blog1Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-secondaryBlack mb-2 font-medium">
                    {item.title}
                  </h3>
                )}
                <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                  {item.desc}
                </p>
                {item.secondaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.secondaryDesc}
                  </p>
                )}
                {item.heading && (
                  <h3 className="sm:text-lg text-secondaryBlack leading-[33px] mb-1">
                    {item.heading}
                  </h3>
                )}
                {item.ternaryDesc && (
                  <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                    {item.ternaryDesc}
                  </p>
                )}
              </div>
            ))
          : null}

        {articleId === "3"
          ? blog3Items.map((item, idx) => (
              <div key={idx}>
                {item.title && (
                  <h3 className="leading-[33px] sm:text-2xl text-lg text-secondaryBlack mb-2 font-medium relative">
                    {idx !== blog3Items.length - 1 && (
                      <span className="w-1.5 h-1.5 absolute left-0 top-[40%] translate-x-[-40%] bg-secondaryBlack rounded-full"></span>
                    )}
                    <span className="ms-5">{item.title}</span>
                  </h3>
                )}
                <p className="sm:text-lg text-secondaryBlack leading-[33px] mb-5">
                  {item.desc}
                </p>
              </div>
            ))
          : null}
      </div>
    </section>
  );
};

export default ArticleBody;
