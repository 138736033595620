import React from "react";

const Comments = () => {
  return (
    <div className="container min-w-full md:px-[40px] lg:mb-[100px] nd:mb-[60px] mb-[30px]">
      <button className="py-5 w-full bg-ternaryBlack text-textWhite my-5 tracking-[4px] rounded">
        View Comments (0)
      </button>
    </div>
  );
};

export default Comments;
