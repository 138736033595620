import React from "react";
import Header from "../../general/Header";

const LearnAboutUs = () => {
  return (
    <div className="container sm:pb-[60px] pb-[30px] sm:pt-[40px] pt-[20px] min-w-full md:px-[40px] mx-0">
      <Header title="Learn About Us" />
      <h3 className="sm:text-[44px] text-[32px] md:max-w-[1150px] text-textColor font-normal">
        Embark a Journey{" "}
        <span className="text-secondaryText">
          of Discovery and Adventures as You Explore the Worlds Most Captivating
          Places
        </span>{" "}
        While Making Money.
      </h3>
    </div>
  );
};

export default LearnAboutUs;
