import React, { useEffect } from "react";
import PageHeader from "../../components/pages/blogs/singleBlog/PageHeader";
import ArticleBody from "../../components/pages/blogs/singleBlog/ArticleBody";
import Shares from "../../components/pages/blogs/singleBlog/Shares";
import Comments from "../../components/pages/blogs/singleBlog/Comments";
import YouMayLike from "../../components/pages/blogs/singleBlog/YouMayLike";
import { useParams } from "react-router-dom";

const SingleBlogPage = () => {
  const { blogId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blogId]);

  return (
    <div>
      <PageHeader articleId={blogId} />
      <ArticleBody articleId={blogId} />
      <Shares />
      <Comments />
      <YouMayLike />
    </div>
  );
};

export default SingleBlogPage;
