import React, { useEffect } from "react";
import Hero from "../components/pages/home/Hero";
import LearnAboutUs from "../components/pages/home/LearnAboutUs";
import WhoIsItFor from "../components/pages/home/WhoIsItFor";
import CoreFeatures from "../components/pages/home/CoreFeatures";
import SuccessfulMembers from "../components/pages/home/SuccessfulMembers";
import BlogsHighlights from "../components/pages/home/BlogsHighlights";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Hero />
      <LearnAboutUs />
      <WhoIsItFor />
      <CoreFeatures />
      <SuccessfulMembers />
      <BlogsHighlights />
    </div>
  );
};

export default HomePage;
