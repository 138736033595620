import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0006 4.00056V17.0006C18.0006 17.2658 17.8952 17.5201 17.7077 17.7077C17.5201 17.8952 17.2658 18.0006 17.0006 18.0006H4.00056C3.73534 18.0006 3.48099 17.8952 3.29345 17.7077C3.10591 17.5201 3.00056 17.2658 3.00056 17.0006C3.00056 16.7353 3.10591 16.481 3.29345 16.2934C3.48099 16.1059 3.73534 16.0006 4.00056 16.0006H14.5868L0.293056 1.70806C0.105415 1.52042 -1.97712e-09 1.26592 0 1.00056C1.97712e-09 0.735192 0.105415 0.480697 0.293056 0.293056C0.480697 0.105415 0.735192 1.97712e-09 1.00056 0C1.26592 -1.97712e-09 1.52042 0.105415 1.70806 0.293056L16.0006 14.5868V4.00056C16.0006 3.73534 16.1059 3.48099 16.2934 3.29345C16.481 3.10591 16.7353 3.00056 17.0006 3.00056C17.2658 3.00056 17.5201 3.10591 17.7077 3.29345C17.8952 3.48099 18.0006 3.73534 18.0006 4.00056Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowDown;
