import React from "react";
import Location from "../../icons/Location";
import { Image } from "@nextui-org/react";

const MemberCard = ({ member }) => {
  return (
    <div className={`lg:w-auto sm:w-[calc(50%-20px)] min-[540px]:w-[75%]`}>
      <Image
        src={member.image}
        alt={member.name}
        classNames={{
          wrapper:
            "xl:w-[373px] sm:w-full w-[80%] xl:h-[435px] rounded-none sm:mx-0 mx-auto",
          img: "xl:w-[373px] sm:full xl:h-[435px]",
        }}
        className="rounded-none size-full"
        loader={<div className="bg-gray-200 w-full h-full" />}
      />
      <div className="p-5 sm:text-left text-center">
        <h4 className="text-lg font-medium">{member.name}</h4>
        <p className="flex sm:justify-start justify-center items-center gap-2 font-medium text-secondaryText my-4">
          <Location /> <span>{member.location}</span>
        </p>
        <p className="sm:max-w-[333px] text-xl font-light leading-[32px]">
          {member.desc}
        </p>
      </div>
    </div>
  );
};

export default MemberCard;
