import { Button } from "@nextui-org/react";
import { ArrowRight } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const OurPurpose = () => {
  return (
    <section className="bg-[url('/public/images/travelAwards/ourPurpose.jpg')] container min-w-full bg-cover xl:px-[120px] relative lg:px-[80px] md:px-[60px] sm:px-[30px] sm:py-[90px] py-[45px] flex">
      <div className="p-[30px] sm:p-[60px] rounded-xl bg-textWhite border-1 border-borderColor relative z-20 shadow-mainShadow">
        <h2 className="font-bold sm:text-[30px] text-2xl text-textColor mb-5 leading-[36px]">
          Our Purpose
        </h2>
        <p className="max-w-[375px] font-light text-textColor leading-[24px]">
          The Travel Award was established to recognize the vital role travel
          creators play in the global tourism industry, shaping how we explore
          and understand our world. From uncovering hidden gems to championing
          sustainable tourism, the Travel Award applauds those who leave an
          indelible mark on travel culture
        </p>
        <Link
          to={"/contact"}
          className="bg-textColor text-textWhite flex items-center gap-1 w-fit rounded-md font-bold mt-5 p-4 shadow-mainShadow"
        >
          Register as a Contester <ArrowRight size={18} />
        </Link>
      </div>
      <div className="absolute left-0 right-0 bottom-0 top-0 bg-heroGradiant"></div>
    </section>
  );
};

export default OurPurpose;
