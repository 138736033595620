export const members = [
  {
    id: 1,
    name: "Aly Mansy",
    location: "Egypt",
    desc: "Access our monetization toolkit, webinars, and workshops led by experienced bloggers who’ve made their passion profitable.",
    image: "/images/home/member1.png",
  },
  {
    id: 2,
    name: "Mahmoud Mansy",
    location: "UAE",
    desc: "Access our monetization toolkit, webinars, and workshops led by experienced bloggers who’ve made their passion profitable.",
    image: "/images/home/member2.png",
  },
  {
    id: 3,
    name: "Amr Elmasry",
    location: "Egypt",
    desc: "Access our monetization toolkit, webinars, and workshops led by experienced bloggers who’ve made their passion profitable.",
    image: "/images/home/member3.png",
  },
];
