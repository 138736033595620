import React from "react";
import Header from "../../general/Header";

const WhyAwardsMatter = () => {
  return (
    <section className="container sm:pt-[60px] sm:pb-[40px] pt-[30px] pb-[20px] min-w-full md:px-[60px] mx-0">
      <h3 className="font-medium sm:text-[40px] text-[32px] mb-8 text-textColor">
        <span className="text-secondaryText">Why</span> Travel Bloggers Awards{" "}
        <span className="text-secondaryText">Matter</span>
      </h3>
      <img
        src="/images/about/awards.png"
        alt="Why Awards Matter"
        height={505}
      />
      <div className="mt-10 flex lg:flex-nowrap flex-wrap items-start sm:justify-between justify-center lg:gap-x-10 gap-y-[25px]">
        <div className="xl:w-[340px] lg:w-[280px] sm:w-[calc((100%/2)-40px)] w-full xl:text-left text-center text-textColor">
          <h4 className="text-2xl font-medium mb-5 leading-8">
            Inspiring Wanderlust
          </h4>
          <p className="text-lg leading-[30px] font-light">
            Empowering people to step out of their comfort zones.
          </p>
        </div>
        <div className="xl:w-[340px] lg:w-[280px] sm:w-[calc((100%/2)-40px)] w-full xl:text-left text-center text-textColor">
          <h4 className="text-2xl font-medium mb-5 leading-8">
            Cultural Connection
          </h4>
          <p className="text-lg leading-[30px] font-light">
            Bridging gaps between diverse cultures.
          </p>
        </div>
        <div className="xl:w-[340px] lg:w-[280px] sm:w-[calc((100%/2)-40px)] w-full lg:flex-none flex-1 xl:text-left text-center text-textColor">
          <h4 className="text-2xl font-medium mb-5 leading-8">
            Industry Growth
          </h4>
          <p className="text-lg leading-[30px] font-light">
            Recognizing blogging as a vital component of the travel industry.
          </p>
        </div>
      </div>
      <div className="sm:mt-[120px] mt-[60px] sm:mb-[80px] mb-[40px]">
        <Header title="Our Story" />
        <p className="sm:text-[32px] text-2xl leading-[42px] pt-3 text-textColor">
          What started as a passion project among avid travelers has now become
          a global platform honoring bloggers for their impact on tourism and
          storytelling. The journey began in 2015, when a group of travel
          enthusiasts decided to celebrate the unsung heroes of travel media
        </p>
      </div>
    </section>
  );
};

export default WhyAwardsMatter;
