export const navLinks = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "About Us",
    href: "/about-us",
  },
  {
    name: "Travel Awards",
    href: "/travel-awards",
  },
  {
    name: "Travel Blogs",
    href: "/travel-blogs",
  },
];
