export const blogs = [
  {
    id: 1,
    title: "Integer Maecenas Eget Viverra",
    body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
    author: "Joanna Wellick",
    date: "June 28, 2018",
    articleAvatar: "/images/authorAvatar.png",
    background: "/images/blog1.png",
    cat1: "Aenean Eleifend",
    cat2: "Aliquam",
  },
  {
    id: 2,
    title: "Integer Maecenas Eget Viverra",
    body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
    author: "Joanna Wellick",
    date: "June 28, 2018",
    articleAvatar: "/images/authorAvatar.png",
    background: "/images/blog2.png",
    cat1: "Aenean Eleifend",
    cat2: "Aliquam",
  },
  {
    id: 3,
    title: "Integer Maecenas Eget Viverra",
    body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
    author: "Joanna Wellick",
    date: "June 28, 2018",
    articleAvatar: "/images/authorAvatar.png",
    background: "/images/blog3.png",
    cat1: "Aenean Eleifend",
    cat2: "Aliquam",
  },
  // {
  //   id: 4,
  //   title: "Integer Maecenas Eget Viverra",
  //   body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
  //   author: "Joanna Wellick",
  //   date: "June 28, 2018",
  //   articleAvatar: "/images/authorAvatar.png",
  //   background: "/images/blog1.png",
  //   cat1: "Aenean Eleifend",
  //   cat2: "Aliquam",
  // },
  // {
  //   id: 5,
  //   title: "Integer Maecenas Eget Viverra",
  //   body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
  //   author: "Joanna Wellick",
  //   date: "June 28, 2018",
  //   articleAvatar: "/images/authorAvatar.png",
  //   background: "/images/blog2.png",
  //   cat1: "Aenean Eleifend",
  //   cat2: "Aliquam",
  // },
  // {
  //   id: 6,
  //   title: "Integer Maecenas Eget Viverra",
  //   body: "Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem.\n",
  //   author: "Joanna Wellick",
  //   date: "June 28, 2018",
  //   articleAvatar: "/images/authorAvatar.png",
  //   background: "/images/blog3.png",
  //   cat1: "Aenean Eleifend",
  //   cat2: "Aliquam",
  // },
];
