import React from "react";
import Header from "../../general/Header";

const BecomeAContestant = () => {
  return (
    <div className="container md:px-[40px] min-w-full my-6 sm:my-12">
      <Header title="Become a contestant" />
      <h3 className="sm:text-[44px] text-[32px] max-w-[1150px] text-textColor leading-[50.56px]">
        <span className="text-secondaryText ">Want to become a</span> Travel
        Travel Blogger
      </h3>
      <p className="my-5 text-lg font-light sm:text-xl">
        Fill out the form below to apply as a contestant and take the first step
        toward your big break. Our team reviews every application carefully, so
        be sure to provide as much detail as possible about yourself and what
        makes you stand out.
      </p>
    </div>
  );
};

export default BecomeAContestant;
