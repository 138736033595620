import React from "react";

const PageHeader = () => {
  return (
    <div className="bg-[url('/public/images/about/header.png')] bg-cover h-[384px] relative flex items-center justify-center">
      <div className="container relative z-20">
        <p className="sm:text-[48px] text-[32px] text-textWhite text-center font-bold uppercase">
          About us
        </p>
      </div>
      <div className="absolute left-0 right-0 bottom-0 h-[384px] bg-heroGradiant"></div>
    </div>
  );
};

export default PageHeader;
