import { Image } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ article, page }) => {
  return (
    <div
      className={`shadow-mainShadow ${
        page === "blogs"
          ? "w-full"
          : "xl:w-[calc(50%-20px)] lg:w-[calc(50%-80px)] md:w-[calc(50%-20px)] min-[540px]:w-[75%]"
      }`}
    >
      <div className="relative">
        <Image
          classNames={{ wrapper: "size-full rounded-none min-h-[281px]" }}
          className="rounded-none size-full"
          src={article.background}
          alt={article.title}
        />
        <div className="absolute inset-0 bg-articleCardOverlay z-20" />
        <div className="absolute top-5 left-4 flex gap-2 z-30">
          <p className="text-textWhite px-2.5 py-2 rounded-[3px] bg-articleNameBg backdrop-blur-md">
            {article.cat1}
          </p>
          <p className="text-textWhite px-2.5 py-2 rounded-[3px] bg-articleNameBg backdrop-blur-md">
            {article.cat2}
          </p>
        </div>
      </div>
      <div className="px-4">
        <h3 className="text-secondaryBlack sm:text-2xl text-lg leading-[40px] mt-3">
          {article.title}
        </h3>
        <div className="flex items-center gap-2 my-4">
          <div className="flex items-center gap-1">
            <Image
              src={article.articleAvatar}
              alt={article.author}
              className="size-full rounded-none"
              classNames={{ wrapper: "w-5 h-5 rounded-none" }}
            />
            <p className="text-secondaryBlack leading-[40px]">
              {article.author}
            </p>
          </div>
          <div className="min-[390px]:w-9 w-4 h-px bg-secondaryGray opacity-40" />
          <p className="text-sm text-secondaryGray">{article.date}</p>
        </div>
        <p className="text-secondaryGray text-sm">{article.body}</p>
        <Link
          to={`/travel-blogs/blog/${article.id}`}
          className="text-secondaryBlack underline underline-offset-[12px] block mt-5 mb-8 leading-[20.96px]"
        >
          View Post
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
