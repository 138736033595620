import React from "react";

const Location = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0686 1.76057L10.1936 16.7606C10.136 16.8862 10.0385 16.9893 9.91618 17.0536C9.79387 17.1179 9.65369 17.1399 9.51757 17.116C9.38144 17.0922 9.25705 17.024 9.16385 16.9219C9.07065 16.8199 9.01389 16.6899 9.00245 16.5521L8.4212 9.5792L1.44826 8.99795C1.31054 8.98651 1.1805 8.92975 1.07847 8.83655C0.976439 8.74334 0.908176 8.61895 0.884356 8.48283C0.860535 8.34671 0.882501 8.20653 0.946817 8.08421C1.01113 7.9619 1.11417 7.86435 1.23982 7.80683L16.2398 0.931825C16.3558 0.878682 16.4852 0.862292 16.6108 0.884858C16.7363 0.907423 16.852 0.967862 16.9422 1.05806C17.0323 1.14826 17.0928 1.26389 17.1154 1.38944C17.1379 1.51498 17.1215 1.64443 17.0684 1.76039L17.0686 1.76057Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};

export default Location;
