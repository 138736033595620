import { z } from "zod";

const ContactSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  phoneNumber: z.string().regex(/^\d+$/, {
    message: "Phone number must contain only digits",
  }),
  email: z.string().email({ message: "Invalid email address" }),
  travelBloggerType: z.string().min(1, { message: "Please select an answer" }),
  tourismType: z.string().min(1, { message: "Please select an answer" }),
  travelCompanions: z.string().min(1, { message: "Please select an answer" }),
  audienceNeeds: z.string().min(1, { message: "Please select an answer" }),
  contentType: z.string().min(1, { message: "Please select an answer" }),
  socialMediaLink: z
    .string()
    .url({ message: "Please enter a valid URL" })
    .optional(),
});

export default ContactSchema;
