import React from "react";
import Header from "../../general/Header";
import { blogs } from "../../../constants/general/blogs";
import ArticleCard from "../../general/ArticleCard";

const BlogsHighlights = () => {
  return (
    <section className="container sm:pt-[40px] sm:pb-[80px] pt-[20px] pb-[40px] min-w-full md:px-[40px] mx-0">
      <Header title="Blogs Highlights" />
      <h3 className="sm:text-[44px] text-[32px] mb-5 sm:mb-10 text-textColor leading-[50.56px]">
        <span className="text-secondaryText">The </span>
        Travel Blogger’s <span className="text-secondaryText">Hub</span>
      </h3>
      <p className="font-light sm:text-xl text-lg leading-8">
        Our blog is dedicated to helping travel bloggers make the most of their
        adventures. From monetization strategies and SEO tips to content
        creation and community highlights, we provide the tools you need to grow
        your blog, reach your audience, and maximize your income. Discover the
        latest insights and get inspired!
      </p>
      <div className="mt-10 flex xl:flex-nowrap flex-wrap xl:justify-start justify-center gap-10">
        {blogs.slice(0, 3).map((blog, idx) => (
          <ArticleCard key={idx} article={blog} />
        ))}
      </div>
    </section>
  );
};

export default BlogsHighlights;
