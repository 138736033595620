import { Image } from "@nextui-org/react";
import React from "react";

const Unique = () => {
  return (
    <section className="container xl:px-[80px] lg:px-[60px] sm:px-10 min-w-full pt-[40px] lg:pb-[120px] md:pb-[80px] pb-[40px]">
      <h2 className="font-bold text-2xl sm:text-[30px] text-textColor text-center mx-auto max-w-[390px] md:mb-20 mb-10">
        What Makes the Travel Award Unique?
      </h2>
      <div className="flex items-start lg:justify-between justify-center lg:flex-nowrap flex-wrap gap-x-20 gap-y-10">
        <div className="lg:w-auto md:w-[44%] sm:w-[60%] w-[90%]">
          <Image
            src="/images/travelAwards/unique1.png"
            alt="What Makes the Travel Award Unique?"
            className={"size-full rounded-lg"}
            classNames={{ wrapper: "xl:w-[336px] xl:h-[246px] rounded-lg" }}
          />
          <h4 className="font-extrabold text-xl mt-4 mb-2 text-textColor">
            Diverse Categories
          </h4>
          <p className="font-light max-w-[336px] text-textColor">
            elebrating a range of travel mediums like blogs, photography, and
            social media.
          </p>
        </div>
        <div className="flex flex-col lg:w-auto md:w-[44%] sm:w-[60%] w-[90%]">
          <div className="max-lg:order-2 max-sm:mt-4">
            <h4 className="font-extrabold text-xl lg:mt-0 mt-4 lg:mb-0 mb-2 text-textColor">
              Global Reach
            </h4>
            <p className="font-light max-w-[336px] lg:mt-2 lg:mb-4 text-textColor">
              Recognizing creators from every corner of the globe.
            </p>
          </div>
          <Image
            src="/images/travelAwards/unique2.png"
            alt="What Makes the Travel Award Unique?"
            className={"size-full rounded-lg"}
            classNames={{ wrapper: "xl:w-[336px] xl:h-[246px] rounded-lg" }}
          />
        </div>
        <div className="lg:w-auto md:w-[44%] sm:w-[60%] w-[90%]">
          <Image
            src="/images/travelAwards/unique3.png"
            alt="What Makes the Travel Award Unique?"
            className={"size-full rounded-lg"}
            classNames={{ wrapper: "xl:w-[336px] xl:h-[246px] rounded-lg" }}
          />
          <h4 className="font-extrabold text-xl mt-4 mb-2 text-textColor">
            Focus on Impact
          </h4>
          <p className="font-light max-w-[336px] text-textColor">
            Highlighting content that inspires change, fosters inclusivity, and
            promotes sustainability.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Unique;
